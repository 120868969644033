
/* eslint-disable */
import {Component, Vue, Watch, namespace} from 'nuxt-property-decorator'
import {User} from '@pp-frontend/authorization/types/User'

const user = namespace('user')
@Component({
  fetchOnServer: false,
})
export default class extends Vue {
  isWaitConfirm: boolean = false
  formData: Partial<User> = {email: ''}
  isFetched: boolean = false
  hasEmailError: boolean = false

  async fetch() {
    await new Promise((resolve) => {
      window.addEventListener('load', resolve, {once: true})
    }).then(() => {
      return this.$store.dispatch('user/getUser')
    })
    this.formData = Object.assign({}, this.$store.getters['user/getUser'])
  }

  get googleOAuthUrl() {
    return process.env.API_ENDPOINT + `user/auth/google?access-token=${localStorage.getItem('storedToken')}&redirect_to=${this.currentPath}`
  }

  get yandexOAuthUrl() {
    return process.env.API_ENDPOINT + `user/auth/yandex?access-token=${localStorage.getItem('storedToken')}&redirect_to=${this.currentPath}`
  }

  get mailOAuthUrl() {
    return process.env.API_ENDPOINT + `user/auth/mail?access-token=${localStorage.getItem('storedToken')}&redirect_to=${this.currentPath}`
  }

  get currentPath() {
    return encodeURIComponent(window.location.origin + this.$route.fullPath)
  }

  /**
   * Получаем ссылку и домен на почтовый сервис на основе домена
   *
   * @returns {*} url
   */
  get mailServiceUrl() {
    const domain = (this.formData.email as string).split('@')[1]

    switch (domain) {
      case 'gmail.com':
        return {
          url: 'https://mail.google.com/',
          domain: 'gmail.com'
        }
      case 'yandex.ru':
        return {
          url: 'https://mail.yandex.ru/',
          domain: 'yandex.ru'
        }
      case 'mail.ru':
      case 'list.ru':
      case 'inbox.ru':
      case 'bk.ru':
        return {
          url: 'https://e.mail.ru/',
          domain: 'mail.ru'
        }
      case 'icloud.com':
      case 'mac.com':
      case 'me.com':
        return {
          url: 'https://www.icloud.com/mail/',
          domain: 'icloud.com'
        }
      default:
        return {
          url: `https://${domain}/`,

          domain: domain
        }
    }
  }

  @user.State
  user: User

  @Watch('user', {deep: true})
  handleUpdateUser(user: User) {
    this.formData.email = user.email
  }

  @Watch('isWaitConfirm')
  waitChange() {
    if (!this.isWaitConfirm) {
      this.isFetched = false
    }
  }

  sendEmail() {
    if (this.hasEmailError) {
      return
    }

    this.hasEmailError = false
    this.$axios.get(process.env.API_ENDPOINT + `user/send-email-verification?email=${this.formData.email}&redirect_to=${encodeURIComponent(window.location.href)}`).then((response: any) => {
      if (response.data) {
        this.isWaitConfirm = true
        if (!this.isFetched) {
          this.fetchConfirm()
        }
      }
    }).catch((err: any) => {
      this.hasEmailError = err.response.status === 422
    })
  }

  async fetchConfirm() {
    this.isFetched = true
    while (!this.formData.email_confirmed && this.isWaitConfirm) {
      await new Promise((resolve: any) => {
        this.$store.dispatch('user/reloadUser').then(() => {
          this.formData = Object.assign(this.formData, this.$store.getters['user/getUser'])

          if (this.formData.email_confirmed) {
            this.isWaitConfirm = false

            this.$nextTick(() => {
              this.$eventBus.$emit('userConfirm')
              this.$emit('confirm')
            })
          }
          setTimeout(resolve, 5000)
        })
      })
    }
  }

  validEmail() {
    // eslint-disable-next-line
    this.hasEmailError = !/^[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(this.formData.email as string)
  }
}
